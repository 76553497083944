import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { AdminAuthService } from './data/services/admin-auth.service';
import { GeneralSettingsService } from './data/services/general-settings.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'trustrockEnergy';
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private genSettings: GeneralSettingsService,
    private adminAuthService: AdminAuthService
  ) { }

  ngOnInit() {
    this.getSettings();
    this.adminAutoLogin();
  }

  private getSettings() {
    this.genSettings.getGenSettings().subscribe();
  }

  private adminAutoLogin() {
    this.adminAuthService.autoLogin();
  }

  scrollTop() {
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo({
        top: 0
      });
    }
  }
}
